import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I was the primary back-end software engineer for an advanced gift recommendation app called Gifted. Through the Amazon affiliate program, I developed an algorithm that gives users gift recommendations via a Neo4j database. A REST API written in Nodejs using express provides the iOS app with recommendations from the database. Autoscaling AWS EC2 instances deployed with AWS elastic beanstalk were used to run business logic. `}</p>
    <p>{`Here is a screenshot of part of the database:
`}<a parentName="p" {...{
        "href": "/images/giftedgraph.png"
      }}><img parentName="a" {...{
          "src": "/images/giftedgraph.png",
          "alt": "Gifted Graph"
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      